import http from '@/services/axios.service';
import Vue from 'vue';
import { CompaniesStore } from '../localStorage';
import { createCancelTokenHandler } from '../cancelationHandler';
import { getApiUrl } from '@/shared/utils/helpers';
var EndPoint;
(function (EndPoint) {
    EndPoint["COMPANIES"] = "companies";
    EndPoint["INFOSETS"] = "info_sets";
    EndPoint["COMPANY"] = "company";
    EndPoint["COMPANY_HYPERBOLIC"] = "hyperbolic-tree";
    EndPoint["CNPJS_GROUP"] = "cnpjs_group";
    EndPoint["REGISTER_COPY_DATA"] = "copy_data";
})(EndPoint || (EndPoint = {}));
export const storage = {
    getCompanies() {
        return CompaniesStore.keys().then(function (keys) {
            return Promise.all(keys.map(function (key) {
                return CompaniesStore.getItem(key).then(function (value) {
                    return { key: key, value: value };
                });
            }));
        });
    },
    async getCurrentCompany() {
        return await CompaniesStore.getItem('currentCompany');
    },
};
export const api = {
    async fetchCompanies({ infosetId, first = false, updatedAt = undefined, }) {
        // By default, build path to get rest of companies
        let path = `${EndPoint.INFOSETS}/${infosetId}/companies`;
        if (first)
            path += '?first_10=true';
        if (updatedAt)
            path += `&updated_at=${updatedAt}`;
        return await Vue.axios
            .get(path)
            .then(res => res)
            .catch(err => err);
    },
    async companiesIsUpdated({ infosetId, updatedAt, }) {
        if (infosetId && updatedAt) {
            //const path = `${EndPoint.INFO_SETS}/${infosetId}/companies?first_10=true&updated_at=${updatedAt}`;
            const path = `${EndPoint.INFOSETS}/${infosetId}/companies`;
            return await Vue.axios
                .get(path)
                .then(res => res)
                .catch(err => err);
        }
    },
    async fetchAllByInfoset({ infosetId, query = '', page = 1, }) {
        const base_api = getApiUrl('v4');
        let path = `${base_api}/info_sets/${infosetId}/companies?page=${page}`;
        if (query) {
            path += `&query=${query.toUpperCase()}`;
        }
        return await Vue.axios
            .get(path)
            .then(res => res)
            .catch((err) => err.response);
    },
    async fetchCompaniesStats({ infosetId, }) {
        return await http
            .get(`${EndPoint.INFOSETS}/${infosetId}/companies/stats`)
            .then(res => res)
            .catch(err => err);
    },
    async getCompanyById(infosetId, companyId) {
        const base_api = getApiUrl('v4');
        return await Vue.axios
            .get(`${base_api}/info_sets/${infosetId}/companies/${companyId}`)
            .then(res => res)
            .catch(err => err);
    },
    async getCompanyByCnpj(cnpj) {
        return await Vue.axios
            .get(`${getApiUrl('v4')}/companies/find_by_cnpj?cnpj=${cnpj}`)
            .then(res => res)
            .catch(err => err);
    },
    async fetchCompaniesStatus({ infosetId, companiesIds, viewAs, }) {
        if (infosetId && companiesIds.length > 0) {
            const companiesIdsStr = JSON.stringify(companiesIds);
            let path = `user_prospecting_data?companies_ids=${companiesIdsStr}`;
            if (viewAs)
                path += `&view_as=${viewAs}`;
            return await Vue.axios
                .get(`${EndPoint.INFOSETS}/${infosetId}/${EndPoint.COMPANY}/${path}`)
                .then(res => res)
                .catch(err => err);
        }
    },
    async fetchCompaniesKanbanByStatus({ infosetId, status = '', page = 1, viewAs = '', query = '', }) {
        let path = `${getApiUrl('v4')}/info_sets/${infosetId}/kanbam?page=${page}`;
        if (status) {
            path += `&status=${status}`;
        }
        if (viewAs) {
            path += `&view_as=${viewAs}`;
        }
        if (query) {
            path += `&query=${query.toUpperCase()}`;
        }
        return await Vue.axios.get(path);
    },
    async updateStatus({ infosetId, companyId, status, }) {
        return await Vue.axios
            .patch(`${EndPoint.INFOSETS}/${infosetId}/${EndPoint.COMPANY}/${companyId}/user_prospecting_data`, {
            user_prospecting_data: {
                status,
            },
        })
            .then(res => res)
            .catch(err => err);
    },
    //GET METRICS STATS
    async getMetricsToInfoset(infosetId, viewAs) {
        let path = 'metrics';
        if (viewAs) {
            path += `?view_as=${viewAs}`;
        }
        return Vue.axios
            .get(`${EndPoint.INFOSETS}/${infosetId}/${path}`)
            .then(res => res)
            .catch(err => err);
    },
    //GET METRICS PHONES
    async getMetricsToPhones(infosetId) {
        return Vue.axios
            .get(`${EndPoint.INFOSETS}/${infosetId}/validated_phones`)
            .then(res => res)
            .catch(err => err);
    },
    //GET METRICS EMAILS
    async getMetricsToEmails(infosetId) {
        return Vue.axios
            .get(`${EndPoint.INFOSETS}/${infosetId}/validated_emails`)
            .then(res => res)
            .catch(err => err);
    },
    //GET STATUS VALIDATIONS EMAIL - PHONE
    async getStatusValidationsPhoneEmail(infosetId) {
        return Vue.axios
            .get(`${EndPoint.INFOSETS}/${infosetId}/validation_status`)
            .then(res => res)
            .catch(err => err);
    },
    async getStatusValidationsPhoneEmailV4(infosetId) {
        const base_api = getApiUrl('v4');
        return Vue.axios
            .get(`${base_api}/info_sets/${infosetId}/validation_status`)
            .then(res => res)
            .catch(err => err);
    },
    //POST VALIDATE PHONES
    async validatePhones(infosetId) {
        return Vue.axios
            .post(`${EndPoint.INFOSETS}/${infosetId}/validate_phones`)
            .then(res => res)
            .catch(err => err);
    },
    async validatePhonesV4(infosetId) {
        const base_api = getApiUrl('v4');
        return Vue.axios
            .post(`${base_api}/info_sets/${infosetId}/request_phone_validation`)
            .then(res => res)
            .catch((err) => err.response);
    },
    //POST VALIDATE EMAILS
    async validateEmails(infosetId) {
        return Vue.axios
            .post(`${EndPoint.INFOSETS}/${infosetId}/validate_emails`, {
            email_validation_origin: 'client',
        })
            .then(res => res)
            .catch(err => err);
    },
    async getCompanyHyperbolicData(cnpj) {
        if (cnpj) {
            return Vue.axios
                .get(`${EndPoint.COMPANY_HYPERBOLIC}?cnpj=${cnpj}`)
                .then(res => res)
                .catch(err => err.response);
        }
    },
    async getCompanyCnpjs(companyCnpj, page = 1) {
        if (companyCnpj) {
            const base_api = getApiUrl('v4');
            return Vue.axios
                .get(`${base_api}/companies/${companyCnpj}/cnpjs_group?page=${page}`, {
                cancelToken: cancelTokenHandlerObject[this.getCompanyCnpjs.name].handleRequestCancellation().token,
            })
                .then(res => res)
                .catch(err => err.response);
        }
    },
    async registerCopyData(companyId, fieldCopied) {
        if (companyId) {
            return Vue.axios
                .post(`${EndPoint.COMPANIES}/${companyId}/${EndPoint.REGISTER_COPY_DATA}`, {
                field_copied: fieldCopied,
            })
                .then(res => res)
                .catch(err => err.response);
        }
    },
    async getAnnotationCategories() {
        return Vue.axios
            .get('account_preferences/keywords_available')
            .then(res => res)
            .catch(err => err.response);
    },
};
// creating the cancel token handler object
const cancelTokenHandlerObject = createCancelTokenHandler(api);
