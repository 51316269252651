import { json, rehydrate } from 'overmind';
import { CompaniesStore } from '@/overmind/localStorage';
import Vue from 'vue';
import { unionBy } from 'lodash';
import { formatCnpj, isAxiosResponse, } from '@/shared/utils/helpers';
import { CompanyStatus } from './state';
export const getCompaniesByInfosetID = async ({ effects, actions: { companies: actions } }, { infosetId, limit = 10, page = 1, query = '' }) => {
    actions.setCompanyStatus(CompanyStatus.FETCHING);
    actions.statusTransition({ loading: true });
    const result = await effects.companies.api.fetchAllByInfoset({
        infosetId,
        page,
        query,
    });
    actions.getPhoneValidationRequestData();
    actions.verifyPhoneValidationRequestInfoset(infosetId);
    if (result && result.data && result.status === 200) {
        actions.setCompanies({
            payload: {
                companies: result.data.companies,
                total: result.data.total,
            },
            pagination: {
                limit,
                page,
                totalPages: Math.ceil(result.data.total / limit),
            },
        });
        actions.statusTransition({ done: true });
        actions.setCompanyStatus(CompanyStatus.FETCH_SUCCESS);
    }
    else {
        actions.setCompanyStatus(CompanyStatus.FETCH_ERROR);
        if (result && result.data && result.status === 401) {
            // @ts-ignore TS2349: property inexistent
            Vue.auth.logout();
        }
        if (result && result.data && result.status === 422) {
            actions.statusTransition({ noFound: true });
        }
    }
    return;
};
export const getCompaniesStatus = async ({ state: { companies: state }, actions: { companies: actions }, effects: { companies: effects }, }, { infosetId, viewAs }) => {
    let result;
    const companiesIds = state.companies.map(company => company.id);
    result = await effects.api.fetchCompaniesStatus({
        infosetId,
        companiesIds,
        viewAs,
    });
    if (result && result.status === 200 && result.data) {
        actions.setCompaniesStatus({ userProspectionData: result.data });
    }
    else {
        if (result && result.status === 401) {
            // @ts-ignore TS2349: property inexistent
            Vue.auth.logout();
        }
    }
    // @ts-ignore TS2349: property inexistent
    result = null;
    return;
};
export const getCompaniesStats = async ({ effects, actions: { companies: actions } }, { infosetId }) => {
    actions.statusTransition({ loading: true });
    const result = await effects.companies.api.fetchCompaniesStats({ infosetId });
    actions.statusTransition({ done: true });
    if (result && result.status === 200) {
        actions.setCompaniesStats({ stats: result.data });
    }
    else {
        // TODO: handle getCompaniesStatus errors
    }
};
export const getCompanyByID = async ({ effects, actions: { companies: actions } }, { infosetId, companyId }) => {
    const result = await effects.companies.api.getCompanyById(infosetId, companyId);
    actions.statusTransition({ done: true });
    if (result && result.status === 200 && result.data) {
        actions.setCurrentCompanyByID(companyId);
        actions.setCurrentCompany(result.data);
    }
    else {
        actions.setErrors(result.errors);
    }
};
export const updateCompanyStatus = async ({ state: { companies: state }, actions: { companies: actions }, effects }, { infosetId, companyId, status }) => {
    actions.statusTransition({ loading: true });
    //optimistic update
    const oldCompanies = json(state.companies);
    actions.setCompanyState({ companyId, status });
    actions.statusTransition({ done: true });
    const result = await effects.companies.api.updateStatus({
        infosetId,
        companyId,
        status,
    });
    if (result && (result.status === 200 || result.status === 201)) {
        //actions.setCompanyState({ companyId, status: result.data.status });
    }
    else {
        actions.statusTransition({ error: true });
        // TODO: handle updateCompanyStatus error
        state.companies = oldCompanies;
    }
};
export const setCompanyStatus = ({ state: { companies: state } }, status) => {
    state.companyStatus = status;
};
export const setCompaniesStatus = async ({ state: { companies: state } }, { userProspectionData }) => {
    if (userProspectionData.data.length > 0) {
        const newCompanies = state.companies.map(company => {
            const data = userProspectionData.data.find(data => data.attributes.company_id === company.id);
            if (data) {
                company.status = data.attributes.status;
            }
            return company;
        });
        state.companies = newCompanies;
    }
};
export const getCompanyHyperbolicData = async ({ state: { companies: state }, actions: { companies: actions }, effects }, { cnpj }) => {
    const cnpjRaw = formatCnpj(cnpj);
    if (cnpjRaw) {
        const result = await effects.companies.api.getCompanyHyperbolicData(cnpjRaw);
        if (result.status == 200 && result.data) {
            let nodes = [];
            if (result.data.nodes) {
                nodes = result.data.nodes.map((node, index) => {
                    const n = {
                        id: index,
                        name: node.name,
                        iconType: node.icon_type,
                        area: node.main_cnae_group_des,
                    };
                    if (node.cnpj)
                        n['cnpj'] = node.cnpj;
                    if (node.cpf)
                        n['cpf'] = node.cpf;
                    return n;
                });
            }
            actions.setHyperbolicData({
                nodes: nodes,
                links: result.data.edges,
            });
        }
    }
};
export const getCompaniesForKanban = async ({ actions: { companies: actions }, effects }, { infosetId, status, page, viewAs, query }) => {
    const result = await effects.companies.api.fetchCompaniesKanbanByStatus({
        infosetId,
        status,
        page: page,
        viewAs,
        query,
    });
    let found = false;
    if (result && result.status && result.status === 200 && result.data) {
        if (result.data.length > 0) {
            found = true;
            const companies = result.data.map(company => ({ ...company, status }));
            if (status)
                actions.setCompaniesKanban({ companies, status });
        }
    }
    return found ? { status, page: page } : { status, page: --page };
};
export const setCompaniesKanbanState = ({ state: { companies: state } }, value) => {
    state.companiesKanbanStats = value;
};
export const setHyperbolicData = ({ state: { companies: state }, actions: { companies: actions } }, { nodes, links }) => {
    state.hyperbolicData = { nodes, links };
};
export const setCompanies = ({ state: { companies: state }, actions: { companies: actions } }, { payload, pagination: { limit = 10, page = 1, totalPages = 1 } }) => {
    state.companies = payload.companies;
    actions.setTotalPages(totalPages);
    actions.setPage(page);
};
export const setCompaniesKanban = async ({ state: { companies: state } }, { status, companies }) => {
    //let grouped = state.companiesKanban.concat(companies);
    const list = json(state.companiesKanban);
    // update the duplicate with the more recents
    companies.forEach(c => {
        const foundIndex = list.findIndex(k => k.id === c.id);
        if (foundIndex > -1) {
            list[foundIndex] = c;
        }
    });
    state.companiesKanban = unionBy(list, companies, 'id');
};
export const setCompaniesKanbanClear = ({ state: { companies: state }, }) => {
    state.companiesKanban = [];
};
export const setCompaniesStats = ({ state: { companies: state } }, { stats }) => {
    state.stats = stats;
};
export const statusTransition = ({ state: { companies: state } }, { loading = false, idle = false, done = false, error = false, disabledUI = false, noFound = false, }) => {
    state.status.loading = loading;
    state.status.idle = idle;
    state.status.done = done;
    state.status.error = error;
    state.status.disabledUI = disabledUI;
    state.status.noFound = noFound;
};
// TODO: handle the status change to a company in the companies hash
export const setCompanyState = ({ state: { companies: state } }, { companyId, status }) => {
    // TODO: handle set company status filtered by infosetID
    const companies = state.companies.map(company => {
        if (company.id === companyId) {
            company.status = status;
        }
        return company;
    });
    state.companies = json(companies);
};
export const setCurrentCompany = ({ state: { companies: state } }, company) => {
    state.currentCompany = company;
};
export const setCurrentCompanyByID = ({ state: { companies: state } }, companyId) => {
    state.currentCompanyId = companyId;
};
export const setCurrentInfosetByID = ({ state: { companies: state } }, infosetId) => {
    state.currentInfosetId = infosetId;
};
export const resetCurrentCompany = ({ state: { companies: state }, }) => {
    state.currentCompanyId = '';
    state.currentInfosetId = '';
    state.currentCompany = undefined;
    state.companyStatus = CompanyStatus.INITIAL;
};
export const setCompanyDetails = ({ state: { companies: state } }, companyDetails) => { };
export const setViewMode = ({ state: { companies: state } }, viewMode) => {
    state.viewMode = viewMode;
};
export const setTotalPages = ({ state: { companies: state } }, total) => {
    state.totalPages = total;
};
export const setPage = ({ state: { companies: state } }, page) => {
    state.page = page;
};
export const setPagination = ({ state: { companies: state } }, { limit, page, totalPages }) => {
    state.limit = limit;
    state.page = page;
    state.totalPages = Number.isInteger(totalPages) ? totalPages : 1;
};
export const setCompaniesByPage = async ({ state: { companies: state }, actions: { companies: actions } }, { pageNumber }) => {
    let companies = await CompaniesStore.getItem(state.currentInfosetId);
    if (companies) {
        state.companies = json(companies.companies[pageNumber]);
    }
    companies = null;
};
export const setSearchTerm = ({ state: { companies: state } }, searchTerm) => {
    state.searchTerm = searchTerm;
};
export const setErrors = ({ state: { companies: state }, actions: { companies: actions } }, error) => {
    state.error = error;
    actions.statusTransition({ error: true });
};
export const cleanCompaniesKanban = ({ state }) => {
    rehydrate(state, {
        companies: {
            companiesKanban: [],
        },
    });
};
export const getMetricsToInfoset = async ({ state: { companies: state }, effects }, { infosetId, reset, viewAs }) => {
    if (reset) {
        state.statsMetrics = {
            contacted: 0,
            interested: 0,
            successes: 0,
            prospectings: 0,
            contacts: 0,
            /*eslint-disable */
            future_interest: 0,
            discarded: 0,
        };
    }
    state.statusResponse = {
        type: '',
        code: 0,
        message: '',
    };
    await effects.companies.api
        .getMetricsToInfoset(infosetId, viewAs)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            state.statsMetrics = res.data;
        }
        if (res.status === 404) {
            state.statsMetrics = {
                contacted: 0,
                interested: 0,
                successes: 0,
                prospectings: 0,
                contacts: 0,
                /*eslint-disable */
                future_interest: 0,
                discarded: 0,
            };
            state.statusResponse = {
                type: 'get-stats-metrics',
                code: 404,
                message: 'No found',
            };
        }
    })
        .catch(err => {
        if (err) {
            state.statsMetrics = {
                contacted: 0,
                interested: 0,
                successes: 0,
                prospectings: 0,
                contacts: 0,
                /*eslint-disable */
                future_interest: 0,
                discarded: 0,
            };
            state.statusResponse = {
                type: 'get-stats-metrics',
                code: 404,
                message: 'No found',
            };
        }
    });
};
export const getMetricsToPhones = async ({ state: { companies: state }, effects, actions }, { infosetId }) => {
    state.statusResponse = {
        type: '',
        code: 0,
        message: '',
    };
    await effects.companies.api
        .getMetricsToPhones(infosetId)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            state.statsPhones = res.data;
        }
        if (res.status === 404) {
            state.statsPhones = {
                valid_phones: 0,
                total_phones: 0,
                tested_phones: 0,
                invalids_phones: 0,
            };
            state.statusResponse = {
                type: 'Get-phone-validation',
                code: 404,
                message: 'No found',
            };
        }
    })
        .catch(err => {
        if (err) {
            state.statsPhones = {
                valid_phones: 0,
                total_phones: 0,
                tested_phones: 0,
                invalids_phones: 0,
            };
            state.statusResponse = {
                type: 'Get-phone-validation',
                code: 404,
                message: 'No found',
            };
        }
    });
};
export const getStatusValidationsPhoneEmail = async ({ state: { companies: state }, effects, actions }, { infosetId }) => {
    state.statusResponse = {
        type: '',
        code: 0,
        message: '',
    };
    await effects.companies.api
        .getStatusValidationsPhoneEmail(infosetId)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            state.phonesValidationsStatus = res.data.phones_validation_status;
            state.emailsValidationsStatus = res.data.emails_validation_status;
        }
        else {
            state.phonesValidationsStatus = '';
            state.emailsValidationsStatus = '';
        }
    })
        .catch(err => {
        if (err) {
            state.phonesValidationsStatus = '';
            state.emailsValidationsStatus = '';
        }
    });
};
export const getStatusValidationsPhoneEmailV4 = async ({ effects }, { infosetId }) => {
    return effects.companies.api.getStatusValidationsPhoneEmailV4(infosetId);
};
export const getMetricsToEmails = async ({ state: { companies: state }, effects, actions }, { infosetId }) => {
    state.statusResponse = {
        type: '',
        code: 0,
        message: '',
    };
    await effects.companies.api
        .getMetricsToEmails(infosetId)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            state.statsEmails = res.data;
        }
        if (res.status === 404) {
            state.statsEmails = {
                valid_emails: 0,
                invalid_emails: 0,
                maybe_emails: 0,
            };
            state.statusResponse = {
                type: 'Get-email-validation',
                code: 404,
                message: 'No found',
            };
        }
    })
        .catch(err => {
        if (err) {
            state.statsEmails = {
                valid_emails: 0,
                invalid_emails: 0,
                maybe_emails: 0,
            };
            state.statusResponse = {
                type: 'Get-email-validation',
                code: 404,
                message: 'No found',
            };
        }
    });
};
export const validatePhones = async ({ state: { companies: state }, effects, actions }, { infosetId }) => {
    await effects.companies.api
        .validatePhones(infosetId)
        .then(res => {
        state.statusResponse = {
            type: '',
            code: 0,
            message: '',
        };
        if (res.status === 200) {
            state.statusResponse = {
                type: 'Phone-validation',
                code: res.status,
                message: 'Success',
            };
            const dateNow = new Date();
            actions.companies.getStatusValidationsPhoneEmail({ infosetId });
            actions.companies.addPhoneValidationRequestData({
                requestDate: dateNow.toString(),
                infoset_id: infosetId,
            });
            state.showPhonesValidationButton = false;
        }
        else {
            state.statusResponse = {
                type: 'Phone-validation',
                code: 404,
                message: 'Wrong',
            };
        }
    })
        .catch(err => {
        if (err) {
            state.statusResponse = {
                type: 'Phone-validation',
                code: 404,
                message: 'Wrong',
            };
        }
    });
};
export const validatePhonesV4 = async ({ effects }, infosetId) => {
    return effects.companies.api.validatePhonesV4(infosetId);
};
export const validateEmails = async ({ state: { companies: state }, effects, actions }, { infosetId }) => {
    state.statusResponse = {
        type: '',
        code: 0,
        message: '',
    };
    await effects.companies.api
        .validateEmails(infosetId)
        .then(res => {
        if (res.status === 200) {
            state.statusResponse = {
                type: 'Emails-validation',
                code: res.status,
                message: 'Success',
            };
        }
        else {
            state.statusResponse = {
                type: 'Emails-validation',
                code: res.status,
                message: 'Wrong',
            };
        }
    })
        .catch(err => {
        if (err) {
            state.statusResponse = {
                type: 'Emails-validation',
                code: err.status,
                message: 'Wrong',
            };
        }
    });
};
export const cleanStore = ({ state }) => {
    rehydrate(state, {
        companies: {
            status: {
                disabledUI: false,
                done: false,
                error: false,
                loading: false,
                idle: true,
            },
            currentCompanyId: '',
            currentInfosetId: '',
            companies: [],
            searchTerm: '',
            page: 1,
        },
    });
};
export const loadStateFromStorage = ({ state: { companies: state } }, payload) => {
    state.companies = payload.companies ? payload.companies : {};
};
export const addPhoneValidationRequestData = ({ state: { companies: state } }, data) => {
    //@ts-ignore
    state.startedPhonesValidation.push(data);
    localStorage.setItem('StartedPhonesValidationInfosets', JSON.stringify(state.startedPhonesValidation));
};
export const removePhoneValidationRequestData = ({ state: { companies: state } }, infosetId) => {
    const removedInfoset = json(state.startedPhonesValidation.filter(r => r.infoset_id !== infosetId));
    //@ts-ignore
    state.startedPhonesValidation = removedInfoset;
    localStorage.setItem('StartedPhonesValidationInfosets', JSON.stringify(state.startedPhonesValidation));
};
export const getPhoneValidationRequestData = ({ state: { companies: state }, }) => {
    state.startedPhonesValidation = localStorage.StartedPhonesValidationInfosets
        ? JSON.parse(localStorage.StartedPhonesValidationInfosets)
        : [];
};
export const verifyPhoneValidationRequestInfoset = ({ state: { companies: state }, actions: { companies: actions } }, infosetId) => {
    const foundInfoset = infosetId
        ? state.startedPhonesValidation.filter(r => r.infoset_id === infosetId)
        : [];
    if (infosetId && foundInfoset && foundInfoset.length >= 1) {
        const dateNow = new Date();
        const requestedDate = new Date(foundInfoset[0].requestDate);
        //@ts-ignore
        const diffTime = Math.abs(requestedDate - dateNow);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays >= 3) {
            state.showPhonesValidationButton = true;
            actions.removePhoneValidationRequestData(infosetId);
        }
        else {
            state.showPhonesValidationButton = false;
        }
    }
    else if (foundInfoset && foundInfoset.length === 0) {
        state.showPhonesValidationButton = true;
    }
};
export const getCompanyCnpjs = async ({ state: { companies: state }, effects: { companies: effects } }, { cnpj, page = 1 }) => {
    const result = await effects.api.getCompanyCnpjs(cnpj, page);
    if (isAxiosResponse(result)) {
        if (state.currentCompany) {
            if (state.currentCompany.cnpjsGroup) {
                const cnpjs = json(state.currentCompany.cnpjsGroup) || [];
                state.currentCompany.cnpjsGroup = cnpjs.concat(result.data);
                state.currentCompany = json(state.currentCompany);
            }
        }
    }
};
export const registerCopyData = async ({ state: { companies: state }, actions: { companies: actions }, effects: { companies: effects }, }, { companyId, fieldCopied }) => {
    const result = await effects.api.registerCopyData(companyId, fieldCopied);
};
export const getAnnotationCategories = async ({ effects: { companies: effects }, }) => {
    const res = await effects.api.getAnnotationCategories();
    if (res && res.status == 200 && res.data && res.data.keywords) {
        return res.data.keywords;
    }
};
