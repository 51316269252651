import { deburr, isEmpty, values } from 'lodash';
import { date, Notify, Screen } from 'quasar';
import { createWriteStream } from 'streamsaver';
export function uuidv4() {
    // @ts-ignore TS2365
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
}
export const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};
export const objIsEmpty = o => values(o).every(value => value === '' || (typeof value !== 'number' && isEmpty(value)));
export const objHasData = o => !objIsEmpty(o);
export const isResponse = (result) => {
    return result.status !== undefined;
};
export const getWidthByScreen = () => {
    const currentWidth = Screen.width;
    return currentWidth >= 2560
        ? (currentWidth * 72.05) / 100
        : Screen.xl
            ? (currentWidth * 71.1) / 100
            : Screen.lg
                ? (currentWidth * 69.73) / 100
                : Screen.md && currentWidth > 1280
                    ? (currentWidth * 69.1) / 100
                    : currentWidth <= 1280 && currentWidth > 1024
                        ? (currentWidth * 69.1) / 100
                        : currentWidth <= 1024
                            ? currentWidth
                            : currentWidth;
};
export const getSearchDrawerWidth = () => {
    const currentWidth = Screen.width;
    return currentWidth >= 2560
        ? (currentWidth * 72.05) / 100
        : Screen.xl
            ? (currentWidth * 71.1) / 100
            : Screen.lg
                ? (currentWidth * 69.73) / 100
                : Screen.md && currentWidth > 1280
                    ? (currentWidth * 69.2) / 100
                    : currentWidth <= 1280 && currentWidth > 1024
                        ? (currentWidth * 69.1) / 100
                        : currentWidth <= 1024
                            ? currentWidth
                            : currentWidth;
};
export const paginateMap = (mapData, pageSize, page) => {
    const mapTotal = Object.entries(mapData).length;
    const results = Object.keys(mapData)
        .map(function (key, index) {
        while (index < pageSize * page && index >= pageSize * page - pageSize) {
            return mapData[key];
        }
    })
        .filter(c => c !== undefined);
    const totalPages = Math.ceil(mapTotal / pageSize);
    return { results, totalPages };
};
export const paginate = (array, page_size, page_number) => {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    //return array.slice((page_number - 1) * page_size, page_number * page_size);
    const results = array.slice((page_number - 1) * page_size, page_number * page_size);
    const totalPages = Math.ceil(array.length / page_size);
    return { results, totalPages };
};
export const formatNumber = value => {
    return parseInt(value).toLocaleString('pt-BR', {
        minimumIntegerDigits: 1,
    });
};
export const formatCnpjs = cnpjs => {
    if (!cnpjs) {
        return '';
    }
    if (Array.isArray(cnpjs)) {
        return cnpjs.map(cnpj => cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5'));
    }
    return cnpjs
        ? cnpjs.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
        : '';
};
export const formatCnpjsToSessionStorage = (cnpjs) => {
    if (cnpjs === '[]' || cnpjs == null) {
        return [];
    }
    else {
        return cnpjs.split(',').map(item => item.replace(/([^\d])+/gim, ''));
    }
};
export const formatDateForMonthAndYear = data => {
    data.forEach(({ attributes: item }) => {
        item.charged_on = item.charged_on.substr(0, 7).split('-');
    });
    data.map(({ attributes: item }) => {
        if (item.charged_on[1] === '01') {
            item.charged_on = 'Janeiro/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '02') {
            item.charged_on = 'Fevereiro/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '03') {
            item.charged_on = 'Março/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '04') {
            item.charged_on = 'Abril/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '05') {
            item.charged_on = 'Maio/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '06') {
            item.charged_on = 'Junho/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '07') {
            item.charged_on = 'Julho/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '08') {
            item.charged_on = 'Agosto/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '09') {
            item.charged_on = 'Setembro/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '10') {
            item.charged_on = 'Outubro/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '11') {
            item.charged_on = 'Novembro/' + item.charged_on[0];
        }
        if (item.charged_on[1] === '12') {
            item.charged_on = 'Dezembro/' + item.charged_on[0];
        }
    });
    return data;
};
/* Functions helpers to trying to differ between two deep objects (that contain arrays within) */
/*function customizer(baseValue, value) {
  if (Array.isArray(baseValue) && Array.isArray(value)) {
      return _.isEqual(baseValue.sort(), value.sort())
  }
}

export function difference(object, base) {
  function changes(object, base) {
      return _.transform(object, function(result, value, key) {
          if (!_.isEqualWith(value, base[key], customizer)) {
              result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
          }
      });
  }
  return changes(object, base);
}*/
export function replaceNewLines(strValue, replace) {
    return strValue.replace(/[\n]+/gi, replace);
}
export function replaceSpaces(strValue, replace) {
    return strValue.replace(/[\s]+/gi, replace);
}
export function formatCnpj(cnpj) {
    return cnpj ? cnpj.replace(/[^\d]+/gi, '') : '';
}
export function isCnpjValid(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/gi, '');
    if (cnpj == '')
        return false;
    if (cnpj.length != 14)
        return false;
    if (cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999')
        return false;
    let numberLength = cnpj.length - 2;
    let numPart = cnpj.substring(0, numberLength);
    const digits = cnpj.substring(numberLength);
    let sum = 0;
    let pos = numberLength - 7;
    for (let i = numberLength; i >= 1; i--) {
        sum += parseInt(numPart.charAt(numberLength - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != parseInt(digits.charAt(0)))
        return false;
    numberLength = numberLength + 1;
    numPart = cnpj.substring(0, numberLength);
    sum = 0;
    pos = numberLength - 7;
    for (let i = numberLength; i >= 1; i--) {
        sum += parseInt(numPart.charAt(numberLength - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result != parseInt(digits.charAt(1)))
        return false;
    return true;
}
export function formatDate(date) {
    const separator = date.split('-');
    if (separator.length === 1) {
        const day = date.substr(0, 2);
        const year = date.substr(6, 9);
        const month = date.substr(3, 2);
        return `${year}-${month}-${day}`;
    }
    else {
        return date;
    }
}
export function formatDateToBR(date, separator = '/') {
    const year = date.substr(0, 4);
    const month = date.substr(5, 2);
    const day = date.substr(8, 2);
    return `${day}${separator}${month}${separator}${year}`;
}
export function sortItemsByTermAndField({ items, field, term, }) {
    return items.sort((a, b) => {
        const deburedTerm = deburr(term);
        const deburedLabelA = deburr(a[field]);
        const deburedLabelB = deburr(b[field]);
        if (!deburedLabelA.match(new RegExp(deburedTerm, 'i')) &&
            deburedLabelB.match(new RegExp(deburedTerm, 'i'))) {
            return 0;
        }
        if (!deburedLabelB.match(new RegExp(deburedTerm, 'i')) &&
            deburedLabelA.match(new RegExp(deburedTerm, 'i'))) {
            return -1;
        }
        if (!deburedLabelB.match(new RegExp(deburedTerm, 'i')) &&
            !deburedLabelB.match(new RegExp(deburedTerm, 'i'))) {
            return 1;
        }
        if (deburedLabelA.match(new RegExp(deburedTerm, 'i')) &&
            deburedLabelA.match(new RegExp(deburedTerm, 'i'))['index'] == 0) {
            return -1;
        }
        if (deburedLabelB.match(new RegExp(deburedTerm, 'i')) &&
            deburedLabelB.match(new RegExp(deburedTerm, 'i'))['index'] == 0) {
            return 0;
        }
        return deburedLabelA.match(new RegExp(deburedTerm, 'i'))['index'] <
            deburedLabelB.match(new RegExp(deburedTerm, 'i'))['index']
            ? 0
            : -1;
    });
}
export function parseRevenueValues(values) {
    if (Array.isArray(values) && values.length > 0) {
        const beginValue = values[0];
        const endValue = values[1];
        const firstPos = 1;
        const myMap = new Map();
        /*{ 2 => "80K"}
        { 3 => "360K"}
        { 4 => "1M"}
        { 5 => "5M"}
        { 6 => "10M"}
        { 7 => "30M"}
        { 8 => "100M"}
        { 9 => "300M"}
        { 10 => "500M"}
        { 10 => "+500M"}*/
        myMap.set('80k', 1);
        myMap.set('360k', 2);
        myMap.set('1M', 3);
        myMap.set('5M', 4);
        myMap.set('10M', 5);
        myMap.set('30M', 6);
        myMap.set('100M', 7);
        myMap.set('300M', 8);
        myMap.set('500M', 9);
        myMap.set('+500M', 10);
        if (beginValue === '0') {
            return [firstPos, myMap.get(endValue)];
        }
        return [myMap.get(beginValue) + 1, myMap.get(endValue)];
    }
    return [];
}
export function getRevenueFilterValues(values) {
    const beginValue = values[0];
    const endValue = values[1];
    const firstPos = '0';
    const myMap = new Map();
    myMap.set(1, '80k');
    myMap.set(2, '360k');
    myMap.set(3, '1M');
    myMap.set(4, '5M');
    myMap.set(5, '10M');
    myMap.set(6, '30M');
    myMap.set(7, '100M');
    myMap.set(8, '300M');
    myMap.set(9, '500M');
    myMap.set(10, '+500M');
    if (beginValue === 1) {
        return [firstPos, myMap.get(endValue)];
    }
    return [myMap.get(beginValue - 1), myMap.get(endValue)];
}
export function AllStates() {
    const states = [
        { label: 'Acre', value: 'AC' },
        { label: 'Alagoas', value: 'AL' },
        { label: 'Amapá', value: 'AP' },
        { label: 'Amazonas', value: 'AM' },
        { label: 'Bahia', value: 'BA' },
        { label: 'Ceará', value: 'CE' },
        { label: 'Distrito Federal', value: 'DF' },
        { label: 'Espírito Santo', value: 'ES' },
        { label: 'Goías', value: 'GO' },
        { label: 'Maranhão', value: 'MA' },
        { label: 'Mato Grosso', value: 'MT' },
        { label: 'Mato Grosso do Sul', value: 'MS' },
        { label: 'Minas Gerais', value: 'MG' },
        { label: 'Pará', value: 'PA' },
        { label: 'Paraíba', value: 'PB' },
        { label: 'Paraná', value: 'PR' },
        { label: 'Pernambuco', value: 'PE' },
        { label: 'Piauí', value: 'PI' },
        { label: 'Rio de Janeiro', value: 'RJ' },
        { label: 'Rio Grande do Norte', value: 'RN' },
        { label: 'Rio Grande do Sul', value: 'RS' },
        { label: 'Rondônia', value: 'RO' },
        { label: 'Roraíma', value: 'RR' },
        { label: 'Santa Catarina', value: 'SC' },
        { label: 'São Paulo', value: 'SP' },
        { label: 'Sergipe', value: 'SE' },
        { label: 'Tocantins', value: 'TO' },
    ];
    return states;
}
export function AllCountries() {
    const countries = [
        'Afeganistão',
        'África do Sul',
        'Albânia',
        'Alemanha',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antártida',
        'Antígua e Barbuda',
        'Antilhas Holandesas',
        'Arábia Saudita',
        'Argélia',
        'Argentina',
        'Armênia',
        'Aruba',
        'Austrália',
        'Áustria',
        'Azerbaijão',
        'Bahamas',
        'Bahrein',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Bélgica',
        'Belize',
        'Benin',
        'Bermudas',
        'Bolívia',
        'Bósnia-Herzegóvina',
        'Botsuana',
        'Brasil',
        'Brunei',
        'Bulgária',
        'Burkina Fasso',
        'Burundi',
        'Butão',
        'Cabo Verde',
        'Camarões',
        'Camboja',
        'Canadá',
        'Cazaquistão',
        'Chade',
        'Chile',
        'China',
        'Chipre',
        'Cingapura',
        'Colômbia',
        'Congo',
        'Coréia do Norte',
        'Coréia do Sul',
        'Costa do Marfim',
        'Costa Rica',
        'Croácia (Hrvatska)',
        'Cuba',
        'Dinamarca',
        'Djibuti',
        'Dominica',
        'Egito',
        'El Salvador',
        'Emirados Árabes Unidos',
        'Equador',
        'Eritréia',
        'Eslováquia',
        'Eslovênia',
        'Espanha',
        'Estados Unidos',
        'Estônia',
        'Etiópia',
        'Fiji',
        'Filipinas',
        'Finlândia',
        'França',
        'Gabão',
        'Gâmbia',
        'Gana',
        'Geórgia',
        'Gibraltar',
        'Grã-Bretanha (Reino Unido, UK)',
        'Granada',
        'Grécia',
        'Groelândia',
        'Guadalupe',
        'Guam (Território dos Estados Unidos)',
        'Guatemala',
        'Guernsey',
        'Guiana',
        'Guiana Francesa',
        'Guiné',
        'Guiné Equatorial',
        'Guiné-Bissau',
        'Haiti',
        'Holanda',
        'Honduras',
        'Hong Kong',
        'Hungria',
        'Iêmen',
        'Ilha Bouvet (Território da Noruega)',
        'Ilha do Homem',
        'Ilha Natal',
        'Ilha Pitcairn',
        'Ilha Reunião',
        'Ilhas Aland',
        'Ilhas Cayman',
        'Ilhas Cocos',
        'Ilhas Comores',
        'Ilhas Cook',
        'Ilhas Faroes',
        'Ilhas Falkland (Malvinas)',
        'Ilhas Geórgia do Sul e Sandwich do Sul',
        'Ilhas Heard e McDonald (Território da Austrália)',
        'Ilhas Marianas do Norte',
        'Ilhas Marshall',
        'Ilhas Menores dos Estados Unidos',
        'Ilhas Norfolk',
        'Ilhas Seychelles',
        'Ilhas Solomão',
        'Ilhas Svalbard e Jan Mayen',
        'Ilhas Tokelau',
        'Ilhas Turks e Caicos',
        'Ilhas Virgens (Estados Unidos)',
        'Ilhas Virgens (Inglaterra)',
        'Ilhas Wallis e Futuna',
        'índia',
        'Indonésia',
        'Irã',
        'Iraque',
        'Irlanda',
        'Islândia',
        'Israel',
        'Itália',
        'Jamaica',
        'Japão',
        'Jersey',
        'Jordânia',
        'Kênia',
        'Kiribati',
        'Kuait',
        'Laos',
        'Látvia',
        'Lesoto',
        'Líbano',
        'Libéria',
        'Líbia',
        'Liechtenstein',
        'Lituânia',
        'Luxemburgo',
        'Macau',
        'Macedônia (República Yugoslava)',
        'Madagascar',
        'Malásia',
        'Malaui',
        'Maldivas',
        'Mali',
        'Malta',
        'Marrocos',
        'Martinica',
        'Maurício',
        'Mauritânia',
        'Mayotte',
        'México',
        'Micronésia',
        'Moçambique',
        'Moldova',
        'Mônaco',
        'Mongólia',
        'Montenegro',
        'Montserrat',
        'Myanma',
        'Namíbia',
        'Nauru',
        'Nepal',
        'Nicarágua',
        'Níger',
        'Nigéria',
        'Niue',
        'Noruega',
        'Nova Caledônia',
        'Nova Zelândia',
        'Omã',
        'Palau',
        'Panamá',
        'Papua-Nova Guiné',
        'Paquistão',
        'Paraguai',
        'Peru',
        'Polinésia Francesa',
        'Polônia',
        'Porto Rico',
        'Portugal',
        'Qatar',
        'Quirguistão',
        'República Centro-Africana',
        'República Democrática do Congo',
        'República Dominicana',
        'República Tcheca',
        'Romênia',
        'Ruanda',
        'Rússia (antiga URSS) - Federação Russa',
        'Saara Ocidental',
        'Saint Vincente e Granadinas',
        'Samoa Americana',
        'Samoa Ocidental',
        'San Marino',
        'Santa Helena',
        'Santa Lúcia',
        'São Bartolomeu',
        'São Cristóvão e Névis',
        'São Martim',
        'São Tomé e Príncipe',
        'Senegal',
        'Serra Leoa',
        'Sérvia',
        'Síria',
        'Somália',
        'Sri Lanka',
        'St. Pierre and Miquelon',
        'Suazilândia',
        'Sudão',
        'Suécia',
        'Suíça',
        'Suriname',
        'Tadjiquistão',
        'Tailândia',
        'Taiwan',
        'Tanzânia',
        'Território Britânico do Oceano índico',
        'Territórios do Sul da França',
        'Territórios Palestinos Ocupados',
        'Timor Leste',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunísia',
        'Turcomenistão',
        'Turquia',
        'Tuvalu',
        'Ucrânia',
        'Uganda',
        'Uruguai',
        'Uzbequistão',
        'Vanuatu',
        'Vaticano',
        'Venezuela',
        'Vietnã',
        'Zâmbia',
        'Zimbábue',
    ];
    return countries;
}
export function downloadFile(data, filename) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    //link.setAttribute('download', res.headers['content-disposition'].split(/"/)[1]);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}
export function isAxiosResponse(result) {
    return result ? result.status !== undefined : false;
}
export function isAxiosError(error) {
    return (error.message !== undefined ||
        error.response.statusText !== undefined);
}
// Creator of stream data
export const downloadFileStream = async (url, payload, fileName) => {
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('auth_token_default')}`,
        },
        body: JSON.stringify(payload),
    })
        .then(res => {
        const fileStream = createWriteStream(fileName);
        const writer = fileStream.getWriter();
        if (res.status === 201) {
            // @ts-ignore
            if (res.body.pipeTo) {
                writer.releaseLock();
                return res.body.pipeTo(fileStream);
            }
            const reader = res.body.getReader();
            const pump = () => reader
                .read()
                .then(({ value, done }) => done ? writer.close() : writer.write(value).then(pump));
            return pump();
        }
        else {
            if (res.status >= 399) {
                res.json().then(json => {
                    if (res.status === 404) {
                        Notify.create({
                            type: 'error',
                            message: json.errors[0].summary,
                        });
                    }
                    else if (res.status === 500) {
                        Notify.create({
                            type: 'error',
                            message: json.errors[0].summary,
                        });
                    }
                });
            }
        }
    })
        .catch(err => err.response);
};
export function getApiExportBaseUrl() {
    // return 'http://localhost:3333'
    if (process.env.LOCAL)
        return 'http://localhost:3000';
    if (window.location.hostname.match('dev')) {
        return 'https://integrations.dev.speedio.com.br/';
    }
    if (window.location.hostname.match('test')) {
        return 'https://integrations.staging.speedio.com.br';
        //return 'https://integrations.test.speedio.com.br'
    }
    if (window.location.hostname.match('app')) {
        return 'https://eex.speedio.com.br';
        //return 'https://integrations.app.speedio.com.br'
    }
    if (window.location.hostname.match('staging')) {
        return 'https://integrations.staging.speedio.com.br';
    }
    return 'https://integrations.dev.speedio.com.br/';
}
export function getAppExchangeBaseUrl() {
    // return 'http://localhost:3333'
    if (window.location.hostname.match('dev')) {
        return 'https://app-exchange.dev.speedio.com.br';
    }
    if (window.location.hostname.match('test')) {
        return 'https://app-exchange.test.speedio.com.br';
    }
    if (window.location.hostname.match('app')) {
        return 'https://app-exchange.app.speedio.com.br';
    }
    if (window.location.hostname.match('staging')) {
        return 'https://app-exchange.test.speedio.com.br';
    }
    return 'https://app-exchange.dev.speedio.com.br';
}
export function getApiUrl(version = 'v3') {
    // return `http://localhost:3000/api/${version}`
    if (window.location.hostname.match('dev')) {
        return `https://api-only-internal-export.dev.speedio.com.br/api/${version}`;
    }
    if (window.location.hostname.match('test')) {
        return `https://api.staging.speedio.com.br/api/${version}`;
    }
    if (window.location.hostname.match('app')) {
        return `https://api.speedio.com.br/api/${version}`;
    }
    if (window.location.hostname.match('staging')) {
        return `https://api.staging.speedio.com.br/api/${version}`;
    }
    return `https://api-only-internal-export.dev.speedio.com.br/api/${version}`;
}
export function getApiExportSecondaryBaseUrl() {
    // return 'http://localhost:3333'
    if (process.env.LOCAL)
        return 'http://localhost:3000';
    if (window.location.hostname.match('dev')) {
        return 'https://integrations.dev.speedio.com.br';
    }
    if (window.location.hostname.match('test')) {
        return 'https://integrations.staging.speedio.com.br';
        //return 'https://integrations.test.secondary.speedio.com.br'
    }
    if (window.location.hostname.match('app')) {
        return 'https://eex.speedio.com.br';
    }
    if (window.location.hostname.match('staging')) {
        return 'https://integrations.staging.speedio.com.br';
    }
    return 'https://integrations.dev.speedio.com.br';
}
export function getApiSpeedmailBaseUrl() {
    // return 'http://localhost:3333'
    if (window.location.hostname.match('dev')) {
        return 'https://api-speedmail.dev.speedio.com.br';
    }
    if (window.location.hostname.match('test')) {
        return 'http://api-speedmail.test.speedio.com.br';
        //return 'https://integrations.test.secondary.speedio.com.br'
    }
    if (window.location.hostname.match('app')) {
        return 'https://api-speedmail.app.speedio.com.br';
    }
    if (window.location.hostname.match('staging')) {
        return 'http://api-speedmail.test.speedio.com.br';
    }
    return 'https://api-speedmail.dev.speedio.com.br';
}
export function openDownloadExportFile(filePath) {
    const url = `${getApiExportSecondaryBaseUrl()}/exports/${filePath}`;
    window.location.assign(url);
}
export function isDateKey(key) {
    const values = [
        'dt_exclusao_opcao_simples_formatted',
        'dt_opcao_simples_formatted',
        'dt_situacao_cnpj_formatted',
        'dt_abertura_formatted',
    ];
    return values.includes(key);
}
export function isSliderKey(key) {
    const values = [
        'total_employees_r2016_company',
        'total_employees_r2016_cnpj',
        'revenue_a32_company_code',
        'revenue_a32_cnpj_code',
    ];
    return values.includes(key);
}
export function arrayNotEmpty(value) {
    if (!isEmpty(value)) {
        return true;
    }
    else {
        return false;
    }
}
export const arrIsEmpty = o => values(o)
    .flat()
    .every(e => objIsEmpty(e));
export const isEmail = (email) => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email.trim());
export const beginningOfTheDay = (date) => {
    date.setUTCHours(0, 0, 0, 0);
    return date;
};
export const endOfTheDay = (date) => {
    date.setUTCHours(23, 59, 59, 999);
    return date;
};
export const getFirstDayOfYear = () => beginningOfTheDay(new Date(new Date().getFullYear(), 0, 1));
export const getLastDayOfYear = () => endOfTheDay(new Date(new Date().getFullYear(), 11, 31));
export const getFirstDayOfMonth = () => beginningOfTheDay(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
export const getLastDayOfMonth = () => endOfTheDay(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
export const zerofill = i => (i < 10 ? '0' : '') + i;
export const handleMonthIndex = (dateStr) => {
    const monthIdx = Number(dateStr.split('-')[1]);
    return monthIdx > 0 ? monthIdx - 1 : 0;
};
// 2021-05
export const handleDayIndex = (dateStr) => {
    const dayIdx = Number(dateStr.split('-')[2]);
    return dayIdx ? dayIdx : 1;
};
/*
  2021-05
  2021-05-15
  returnDate
*/
export const getDate = (dateStr) => {
    const year = Number(dateStr.split('-')[0]);
    const month = handleMonthIndex(dateStr);
    const day = handleDayIndex(dateStr);
    return new Date(year, month, day);
};
export const QuasarDateLocaleOptions = {
    months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ],
    days: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
    ],
    daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
    ],
};
export const getDateByFormat = (period, format) => {
    // default ex: 2021-07
    return date.formatDate(getDate(period), format, {
        ...QuasarDateLocaleOptions,
    });
};
export const getDateTimeByFormat = (period, format) => {
    return date.formatDate(new Date(period.valueOf() - new Date(period).getTimezoneOffset() * 60000), format, {
        ...QuasarDateLocaleOptions,
    });
};
export const personNameInitials = (name) => {
    const names = name.split(' ');
    const first = names[0].substr(0, 1).toUpperCase();
    const last = names[names.length - 1].substr(0, 1).toUpperCase();
    return first + last;
};
export const abbreviateName = (name) => {
    const names = name.split(' ');
    const firstName = names[0];
    names.shift();
    const abbr = names.map(n => n.split('')[0]);
    return `${firstName} ${abbr
        .toString()
        .replaceAll(',', '.')
        .toLocaleUpperCase()}`;
};
export const removeHashRevokeUserEmail = (email) => {
    //  Utilizar gm no regex flag
    const regex = /.[a-z0-9]{8}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{12}.desabilitado/gm;
    return email.replace(regex, '');
};
export const handlerCRMMaxLimitByTab = (tab, companiesCount, decisorsCount) => {
    if (tab !== 'csv' && tab !== 'excel') {
        const sendTotal = companiesCount + decisorsCount;
        switch (tab) {
            case 'salesforce':
                return companiesCount > 10000 || decisorsCount > 10000;
            case 'pipedrive':
                return companiesCount > 10000 || decisorsCount > 10000;
            case 'hubspot':
                return companiesCount > 10000 || decisorsCount > 10000;
            case 'ploomes':
                return companiesCount > 10000 || decisorsCount > 10000;
            case 'piperun':
                return companiesCount > 10000 || decisorsCount > 10000;
            case 'agendor':
                return companiesCount > 1500 || decisorsCount > 1500;
            case 'rdstation':
                return companiesCount > 10000 || decisorsCount > 10000;
            case 'bitrix24':
                return companiesCount > 10000 || decisorsCount > 10000;
            default:
                return sendTotal > 300 || companiesCount > 300 || decisorsCount > 300;
        }
    }
    else {
        return false;
    }
};
export const handleMaxLimitByTabtext = (tab) => {
    switch (tab) {
        case 'salesforce':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        case 'pipedrive':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        case 'hubspot':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        case 'ploomes':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        case 'piperun':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        case 'agendor':
            return `
        <li>Limite de 1500 Empresas</li>
        <li>Limite de 1500 Decisors</li>
        `;
        case 'rdstation':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        case 'bitrix24':
            return `
        <li>Limite de 10000 Empresas</li>
        <li>Limite de 10000 Decisors</li>
        `;
        default:
            return;
    }
};
export const transformStringIntoArrayOfTerms = (text) => {
    const termsList = [];
    // this is a "term" of the Array. Ex: ["A term" "12345", "Another term"]
    // split array by [' ' , ; \n]
    const textListBySplit = text.split(/[\s,;]+/);
    textListBySplit.forEach(tag => {
        if (tag && !isNaN(+tag)) {
            termsList.push(tag);
        }
    });
    if (termsList.length === 0)
        return [text];
    return [...new Set(termsList)];
};
export function disableJivo(current, fullyDisableInThisPage = false) {
    const jdiv = document.body.querySelector('jdiv');
    if ((current &&
        (current.role === 'customer_success' || current.role === 'admin')) ||
        fullyDisableInThisPage) {
        if (jdiv) {
            jdiv.classList.add('hidden');
        }
    }
}
export function validatorCPF(strCPF) {
    let sum = 0;
    let rest;
    if (strCPF == '00000000000')
        return false;
    for (let i = 1; i <= 9; i++)
        sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;
    if (rest == 10 || rest == 11)
        rest = 0;
    if (rest != parseInt(strCPF.substring(9, 10)))
        return false;
    sum = 0;
    for (let i = 1; i <= 10; i++)
        sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;
    if (rest == 10 || rest == 11)
        rest = 0;
    if (rest != parseInt(strCPF.substring(10, 11)))
        return false;
    return true;
}
export function validatorCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '')
        return false;
    if (cnpj.length !== 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999')
        return false;
    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != Number(digitos.charAt(0)))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != Number(digitos.charAt(1)))
        return false;
    return true;
}
export function formatCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }).format(value);
}
export const handleRandomValuesRange = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const showNotify = (message, type) => {
    Notify.create({
        message: message,
        type: type,
    });
};
export const convertSubscriptionPrice = (value) => {
    return value / 100;
};
export function removeAccentInName(name) {
    return name.normalize('NFD').replaceAll(/[\u0300-\u036f]/g, '');
}
export function changeSymbolsByDashesInTheName(name) {
    return removeLastSpace(name).replaceAll(/[^a-zA-Z0-9]+/g, '-');
}
export function hasAutoplayActive(searchHistory) {
    return searchHistory.some(history => history.autoplay == true);
}
export function accountIsActive(accountType) {
    return ['active', 'suspended', 'migrated'].includes(accountType);
}
export function removeLastSpace(name) {
    if (name[name.length - 1] == ' ') {
        const temp_name = name.split('');
        temp_name[temp_name.length - 1] = '';
        name = temp_name.join('');
    }
    return name;
}
export const getCreditsResult = (credits, type) => {
    if (type == 'click') {
        const unlimitedHolder = Object.values(credits).find(el => el.type == 'unlimited_click') || {};
        if (unlimitedHolder.length > 0) {
            return 0;
        }
    }
    const creditsHolder = Object.values(credits).filter(el => el.type == type) || {};
    const totalCredits = creditsHolder.reduce((a, b) => a + b.value, 0);
    const consumedCredits = totalCredits - creditsHolder.reduce((a, b) => a + b.remaining, 0);
    const percentageCredits = parseFloat(((consumedCredits / totalCredits) * 100).toFixed(2)) || 0;
    return {
        total: totalCredits,
        consumed: consumedCredits,
        percentage: percentageCredits,
    };
};
export function setClickCounter() {
    const startCounter = '1';
    const counterClickToCopy = localStorage.getItem('counterClickToCopy');
    const counterClickToCopyParseToNumber = Number(counterClickToCopy);
    if (!isEmpty(counterClickToCopy)) {
        if (counterClickToCopyParseToNumber === 5)
            return;
        const sum = counterClickToCopyParseToNumber + 1;
        localStorage.setItem('counterClickToCopy', sum.toString());
    }
    else
        localStorage.setItem('counterClickToCopy', startCounter);
}
export function setNoShowModalDate(flagName) {
    localStorage.setItem(flagName, new Date().toString());
}
export function validPeriodToShowModal(flagName, dayLimit) {
    const showModalDate = localStorage.getItem(flagName);
    if (showModalDate) {
        const today = new Date();
        const differenceBetweenMilliseconds = Math.abs(
        //@ts-ignore
        today - new Date(showModalDate));
        const differenceBetweenDays = Math.ceil(differenceBetweenMilliseconds / (1000 * 60 * 60 * 24));
        return differenceBetweenDays > dayLimit;
    }
    else
        return true;
}
export function checkIfTheSellersFieldShows(sdrs, segment) {
    return (sdrs == 'Nenhum' &&
        ['B2B (Empresas)', 'Ambos (Empresas e pessoa física)'].includes(segment));
}
export function differenceBetweenCnpjAndCompanyTooltip(label) {
    switch (label) {
        case 'Por Empresa':
            return 'Abrange todo o grupo econômico, matriz e filiais';
        case 'Por CNPJ':
            return 'Considera apenas a unidade específica do CNPJ';
        default:
            return '';
    }
}
